import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RequestHelper, { ApiResponse } from '../../helpers/RequestHelper';

const VerifyNewsletter: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams(); // Holt die Suchparameter von der URL

  useEffect(() => {
    const token = searchParams.get('token'); // Token aus den Suchparametern abrufen
    console.log(token)

    handleValidate(token)

//     if (token) {
      
//       verifyToken(token).then(isValid => {
//         if (isValid) {
//           navigate('/success'); 
//         } else {
//           navigate('/error'); // Leitet zur Fehlerseite weiter
//         }
//       }).catch(() => {
//         navigate('/error'); // Bei einem Fehler leite ebenfalls zur Fehlerseite weiter
//       });
//     } else {
//       navigate('/error'); // Wenn kein Token vorhanden ist, leite zur Fehlerseite weiter
//     }
  }, [navigate, searchParams]);

  const handleValidate = async (token: string | null) => {
      const jsonData = { token };
      const result: ApiResponse<string> = await RequestHelper.post<string>('/postVerifyNewsletter', jsonData);
      console.log(result)
  }


  return (
    <div>
      <h1>Verifying your token...</h1>
    </div>
  );
};

export default VerifyNewsletter;
