import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import PAYMHeader from '../../components/PAYMHeader/PAYMHeader';
import PAYMFooter from '../../components/PAYMFooter/PAYMFooter';

function Landingpage() {
  const { t } = useTranslation();

  return (
    <div className="App">
      <Layout>
        <PAYMHeader />

        <Content className="content">
          <center><h1>{t('privacyPolicy')}</h1></center>
          <br /><br />
          <h2>1. {t('controller')}</h2>
          <p>{t('controllerResponsibleForDataProcessing')}<br /><br />

            <strong>IT Dienstleistungen Philipp Sattler</strong><br />
            Haidwang 91<br />
            86687 Kaisheim<br />
            Deutschland/Germany<br /><br />
            hi@philippsattler.de<br />
            +49 160 5516641</p>

          <h2>2. {t('scopeOfDataCollectionAndProcessing')}</h2>
          <p>{t('forPurposeOfSendingNewsletter')}</p>

          <h2>3. {t('purposeOfDataProcessing')}</h2>
          <p>{t('theEmailAdressYouProvide')}</p>

          <h2>4. {t('legalBasisForDataProcessing')}</h2>
          <p>{t('dataProcessingIsBased')}</p>

          <h2>5. {t('dataStorageAndDeletion')}</h2>
          <p>{t('yourDataWillBeStored')}</p>

          <h2>6. {t('transferOfDataToThirdParties')}</h2>
          <p>{t('weDoNotTransferYourPersonalData')}</p>

          <h2>7. {t('withdrawalOfConsent')}</h2>
          <p>{t('youCanWithdrawYouConsent')}</p>

          <h2>8. {t('dataSubjectRigths')}</h2>
          <p>{t('asADataSubjectYouHaveTheFollowingRights')}</p>

          <h2>9. {t('rightToLodge')}</h2>
          <p>{t('ifYouBelieveThat')}</p>
        </Content>

        <PAYMFooter />
      </Layout>
    </div >
  );
}

export default Landingpage;
