import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { Footer } from 'antd/es/layout/layout';

function PAYMFooter() {
    const { i18n } = useTranslation();

    const handleLangueChange = (value: string) => {
        i18n.changeLanguage(value);
    };

    return (
        <Footer className="footer">
            <Select
                style={{ width: 120 }}
                onChange={handleLangueChange}
                defaultValue={'English'}
                options={[
                    { value: 'en', label: 'English' },
                    { value: 'de', label: 'German' },
                ]}
            />
            <hr />
            ©{new Date().getFullYear()} Created by Philipp Sattler
            <br />
            <br />
            <strong>Impressum:</strong><br />
            Philipp Sattler<br />
            Haidwang 91<br />
            86687 Kaisheim
        </Footer>
    );
}

export default PAYMFooter;
