import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Drawer } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { MenuOutlined } from '@ant-design/icons';

function PAYMHeader() {
    const { t } = useTranslation();
    const [drawerVisible, setDrawerVisible] = useState(false);

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    const handleMenuClick = (id: string) => {
        setDrawerVisible(false);
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Header className="header">
            <div><a className="logo" href="/">PAYM</a></div>

            <Menu theme="dark" mode="horizontal" className="desktop-menu">
                <Menu.Item key="about">
                    <a href="#about">{t('about')}</a>
                </Menu.Item>
                <Menu.Item key="gettingStarted">
                    <a href="#gettingstarted">{t('gettingStarted')}</a>
                </Menu.Item>
                <Menu.Item key="contact">
                    <a href="#contact">{t('contact')}</a>
                </Menu.Item>
            </Menu>

            <MenuOutlined className="mobile-menu-icon" onClick={showDrawer} />

            <Drawer
                title="Menu"
                placement="right"
                onClose={closeDrawer}
                visible={drawerVisible}
            >
                <Menu mode="vertical" className="mobile-menu">
                    <Menu.Item onClick={() => handleMenuClick('about')}>
                        {t('about')}
                    </Menu.Item>
                    <Menu.Item onClick={() => handleMenuClick('gettingstarted')}>
                        {t('gettingStarted')}
                    </Menu.Item>
                    <Menu.Item onClick={() => handleMenuClick('contact')}>
                        {t('contact')}
                    </Menu.Item>
                </Menu>
            </Drawer>
        </Header>
    );
}

export default PAYMHeader;
