import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landingpage from './sites/Landingpage/Landingpage';
import NotFound from './sites/NotFound/NotFound';
import VerifyNewsletter from './sites/VerifyNewsletter/VerifyNewsletter';
import PrivacyPolicy from './sites/PrivacyPolicy/PrivacyPolicy';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landingpage />} />
        <Route path="/verify" element={<VerifyNewsletter />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
