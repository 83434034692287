import React, { useState } from 'react';
import './Landingpage.css';
import { useTranslation } from 'react-i18next';
import { Button, Input, Layout, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import RequestHelper, { ApiResponse } from '../../helpers/RequestHelper';
import PAYMFooter from '../../components/PAYMFooter/PAYMFooter';
import PAYMHeader from '../../components/PAYMHeader/PAYMHeader';
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from 'antd/es/checkbox';

function Landingpage() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [checked, setChecked] = useState<boolean>(false);

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  const handleSignupNewsletter = async () => {
    if (checked) {
      if (validateEmail(email)) {
        console.log("Newsletter Email:", email);
        const jsonData = { email };

        const result: ApiResponse<string> = await RequestHelper.post<string>('/postNewsletterSignup', jsonData);

        console.log(result);
        message.success(t('newsletterSignupEmailSend'));
      } else {
        message.error('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
      }
    } else {
      message.error('Bitte akzeptieren Sie die Datenschutzbestimmung');
    }

  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <div className="App">
      <Layout>
        <PAYMHeader />
        <Content className="content">
          <div className="firstImpression">
            <h1>{t('payOnlyTagline')}</h1>
            <h3>Pay as you mail - {t('subheading')}</h3>
            <p>
              {t('whyPayFor')}
              <br />
              {t('ourPayPerUse')}
            </p>
            <div>
              <a href="#about">
                <Button className="button" type="primary">
                  {t('learnMore')}
                </Button>
              </a>
              <a href="#gettingstarted">
                <Button className="button" type="primary">
                  {t('gettingStarted')}
                </Button>
              </a>
            </div>
          </div>

          <section id="gettingstarted">
            <h2>{t('newsletter')}</h2>
            <p>{t('haventLaunchedYet')}</p>
            <div>
              <Input
                id="newsletterEmail"
                type="email"
                placeholder={t('enterYourBestEmail')}
                value={email}
                onChange={handleEmailChange}
              />
              <Button className="button" type="primary" onClick={handleSignupNewsletter}>Sign up</Button>
            </div>
            <Checkbox onChange={handleCheckboxChange}><p>{t('IAcceptThe')}<a href='/privacypolicy'>{t('privacyPolicy')}</a>. {t('unsubscribingSignOutPossible')}</p></Checkbox>
          </section>

        </Content>

        <PAYMFooter />
      </Layout>
    </div >
  );
}

export default Landingpage;
