// const API_BASE_URL = 'http://localhost:8080';
const API_BASE_URL = 'http://137.184.90.155:8080';

export interface ApiResponse<T> {
  data: T;
  error?: string;
}

class RequestHelper {
  static async get<T>(endpoint: string): Promise<ApiResponse<T>> {
    try {
      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return await RequestHelper.handleResponse<T>(response);
    } catch (error) {
      console.error('GET-Anfrage Fehler:', error);
      throw error;
    }
  }

  static async post<T>(endpoint: string, data: unknown): Promise<ApiResponse<T>> {
    try {
      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return await RequestHelper.handleResponse<T>(response);
    } catch (error) {
      console.error('POST-Anfrage Fehler:', error);
      throw error;
    }
  }

  static async put<T>(endpoint: string, data: unknown): Promise<ApiResponse<T>> {
    try {
      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return await RequestHelper.handleResponse<T>(response);
    } catch (error) {
      console.error('PUT-Anfrage Fehler:', error);
      throw error;
    }
  }

  static async delete<T>(endpoint: string): Promise<ApiResponse<T>> {
    try {
      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return await RequestHelper.handleResponse<T>(response);
    } catch (error) {
      console.error('DELETE-Anfrage Fehler:', error);
      throw error;
    }
  }

  static async handleResponse<T>(response: Response): Promise<ApiResponse<T>> {
    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(`HTTP-Fehler! Status: ${response.status}, Nachricht: ${errorMessage}`);
    }

    const data: T = await response.json();
    return { data };
  }
}

export default RequestHelper;
