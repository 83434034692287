import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        resources: {
            en: {
                translation: {
                    helloWorld: "Hello, World",
                    contentComingSoon: "Content is coming soon...",
                    about: "About",
                    gettingStarted: "Getting started",
                    contact: "Contact",
                    payOnlyTagline: "Pay only what you really send",
                    subheading: "your scalable email and newsletter service",
                    whyPayFor: "Why pay for email plans when you only pay for what you actually use? ",
                    ourPayPerUse: "Our pay-per-use service adapts to your needs.",
                    learnMore: "Learn more",
                    newsletterSignupEmailSend: "Please validate the link in the email to receive the newsletter. Consider checking the spam folder.",
                    newsletter: "Newsletter",
                    haventLaunchedYet: "We haven't launched yet, but you can subscribe to our newsletter and stay updated!",
                    enterYourBestEmail: "Enter your best email",
                    IAcceptThe: "I accept the ",
                    privacyPolicy: "Privacy policy",
                    unsubscribingSignOutPossible: "Unsubscribing from the newsletter is possible at any time",

                    controller: "Controller",
                    controllerResponsibleForDataProcessing: "The controller responsible for data processing related to the newsletter is:",
                    scopeOfDataCollectionAndProcessing: "Scope of Data Collection and Processing",
                    forPurposeOfSendingNewsletter: "For the purpose of sending the newsletter, we collect the user’s email address. Additionally, personal data such as first and last name may be optionally collected to personalize the newsletter.",
                    purposeOfDataProcessing: "Purpose of Data Processing",
                    theEmailAdressYouProvide: "The email address you provide is used solely for sending our newsletter. We use the data to inform you regularly about current offers, news, and events.",
                    legalBasisForDataProcessing: "Legal Basis for Data Processing",
                    dataStorageAndDeletion: "Data Storage and Deletion",
                    transferOfDataToThirdParties: "Transfer of Data to Third Parties",
                    withdrawalOfConsent: "Withdrawal of Consent and Newsletter Unsubscription",
                    dataSubjectRigths: "Data Subject Rights",
                    rightToLodge: "Right to Lodge a Complaint with a Supervisory Authority",

                    dataProcessingIsBased: "Data processing is based on your voluntary consent in accordance with Article 6(1)(a) GDPR. The subscription to our newsletter follows a double opt-in procedure to ensure that the subscription was initiated by you. After subscribing, you will receive a confirmation email with a link to complete the registration.",
                    yourDataWillBeStored: "Your data will be stored as long as you remain subscribed to the newsletter. If you revoke your consent or unsubscribe from the newsletter, your data will be immediately deleted.",
                    weDoNotTransferYourPersonalData: "We do not transfer your personal data to third parties unless it is necessary for the delivery of the newsletter (e.g., via an email marketing service provider) or required by law. In such cases, we ensure that the third party complies with the GDPR requirements.",
                    asADataSubjectYouHaveTheFollowingRights: `As a data subject, you have the following rights:
                    Access: You may request information at any time about the personal data we process about you.
                    Rectification: You have the right to correct inaccurate data.
                    Erasure: Under certain circumstances, you may request the deletion of your data.
                    Restriction of Processing: You can request that the processing of your data be restricted.
                    Data Portability: You have the right to receive your data in a structured, commonly used, and machine-readable format.
                    To exercise your rights, please contact [your email address].`,
                    youCanWithdrawYouConsent: "You can withdraw your consent to receive the newsletter at any time and unsubscribe. You will find an unsubscribe link in every newsletter. Alternatively, you can send us an email at [your email address] and request to unsubscribe.",
                    ifYouBelieveThat: "If you believe that we are processing your data unlawfully, you have the right to file a complaint with a data protection supervisory authority. In Germany, this is the data protection authority of your federal state.",

                }
            },
            ////////////////////////////////////////////////////////////// DE //////////////////////////////////////////////////////////////
            de: {
                translation: {
                    helloWorld: "Hallo, Welt",
                    contentComingSoon: "Inhalte folgen bald...",
                    about: "Über",
                    gettingStarted: "Jetzt starten",
                    contact: "Kontakt",
                    payOnlyTagline: "Bezahl nur für das, was du wirklich versendest | Vor Launch CORS und XSS überprüfen",
                    subheading: "dein skalibieres E-Mail und Newsletter Programm",
                    whyPayFor: "Warum monatlich für ein E-Mails zahlen, wenn du nur das zahlen kannst, was du wirklich benutzt?",
                    ourPayPerUse: "Unser Pay-Per-Use Dienst passt sich Deinen Bedürfnissen an.",
                    learnMore: "Erfahre mehr",
                    newsletterSignupEmailSend: "Um den Newsletter zu erhalten, bestätige bitte den Link in der E-Mail. Falls du keine Mail erhalten hast, überprüfe den Spam Ordner.",
                    newsletter: "Newsletter",
                    haventLaunchedYet: "Der Launch steht noch bevor, aber du kannst den Newsletter abonnieren um immer auf dem neusten Stand zu bleiben!",
                    enterYourBestEmail: "Ihre beste E-Mail Adresse",
                    IAcceptThe: "Ich akzeptiere die ",
                    privacyPolicy: "Datenschutzbestimmungen",
                    unsubscribingSignOutPossible: "Die Abmeldung vom Newsletter ist jederzeit möglich.",
                    controller: "Verantwortlicher",
                    controllerResponsibleForDataProcessing: "Verantwortlicher für die Datenverarbeitung im Zusammenhang mit dem Newsletter ist:",
                    scopeOfDataCollectionAndProcessing: "Umfang der Datenerhebung und -verarbeitung",
                    forPurposeOfSendingNewsletter: "Für den Versand des Newsletters erheben wir die E-Mail-Adresse des Nutzers. Optional können auch weitere personenbezogene Daten wie Vor- und Nachname abgefragt werden, um den Newsletter zu personalisieren.",
                    purposeOfDataProcessing: "Zweck der Datenverarbeitung",
                    theEmailAdressYouProvide: "Die von Ihnen angegebene E-Mail-Adresse wird ausschließlich für den Versand unseres Newsletters verwendet. Wir nutzen die Daten, um Sie regelmäßig über aktuelle Angebote, Neuigkeiten und Veranstaltungen zu informieren.",
                    legalBasisForDataProcessing: "Rechtsgrundlage der Datenverarbeitung",
                    dataStorageAndDeletion: "Speicherung und Löschung der Daten",
                    transferOfDataToThirdParties: "Weitergabe der Daten an Dritte",
                    withdrawalOfConsent: "Widerruf der Einwilligung und Abmeldung vom Newsletter",
                    dataSubjectRigths: "Betroffenenrechte",
                    rightToLodge: "Beschwerderecht bei einer Aufsichtsbehörde",
                    dataProcessingIsBased: "Die Datenverarbeitung erfolgt auf Grundlage Ihrer freiwillig erteilten Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO. Die Anmeldung zu unserem Newsletter erfolgt im Double-Opt-in-Verfahren, um sicherzustellen, dass der Anmeldevorgang tatsächlich von Ihnen initiiert wurde. Sie erhalten nach der Anmeldung eine E-Mail mit einem Bestätigungslink, den Sie anklicken müssen, um die Anmeldung abzuschließen.",
                    yourDataWillBeStored: "Ihre Daten werden so lange gespeichert, wie Sie den Newsletter abonniert haben. Wenn Sie Ihre Einwilligung widerrufen oder sich vom Newsletter abmelden, werden Ihre Daten unverzüglich gelöscht.",
                    weDoNotTransferYourPersonalData: "Wir geben Ihre personenbezogenen Daten nicht an Dritte weiter, es sei denn, dies ist für den Versand des Newsletters notwendig (z. B. durch einen Dienstleister für E-Mail-Marketing) oder gesetzlich vorgeschrieben. In diesem Fall stellen wir sicher, dass auch der Dienstleister die Vorgaben der DSGVO einhält.",
                    asADataSubjectYouHaveTheFollowingRights: `Als betroffene Person haben Sie folgende Rechte:
                    Auskunft: Sie können jederzeit Auskunft darüber verlangen, welche personenbezogenen Daten wir von Ihnen verarbeiten.
                    Berichtigung: Sie haben das Recht, unrichtige Daten berichtigen zu lassen.
                    Löschung: Unter bestimmten Umständen können Sie die Löschung Ihrer Daten verlangen.
                    Einschränkung der Verarbeitung: Sie können die Verarbeitung Ihrer Daten einschränken lassen.
                    Datenübertragbarkeit: Sie haben das Recht, Ihre Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
                    Um Ihre Rechte geltend zu machen, wenden Sie sich bitte an [deine E-Mail-Adresse].`,
                    youCanWithdrawYouConsent: "Sie können Ihre Einwilligung zum Erhalt des Newsletters jederzeit widerrufen und sich vom Newsletter abmelden. Hierzu finden Sie in jedem Newsletter einen entsprechenden Abmelde-Link. Alternativ können Sie uns auch eine E-Mail an [deine E-Mail-Adresse] senden und um Abmeldung bitten.",
                    ifYouBelieveThat: "Wenn Sie der Meinung sind, dass wir Ihre Daten unrechtmäßig verarbeiten, haben Sie das Recht, sich bei einer Datenschutzaufsichtsbehörde zu beschweren. In Deutschland ist dies die Datenschutzbehörde Ihres Bundeslandes.",

                }
            }
        }
    })